.page.login {

  height: 100vh;
  width: 100vw;


  .auth-card {
    width: 300px;
    height: 300px;
  }
}